import { env } from '@utils/env';
import React from 'react';
import styled from 'styled-components';

const defaultFooterData: {
  title?: string;
  companyName?: string;
  representative?: string;
  businessRegistrationNumber?: string;
  address?: string;
  phoneNumber?: string;
  version?: string;
  privacyPolicy?: string;
  termsOfService?: string;
  copyright?: string;
} = {
  title: '아이박스주차',
  companyName: '(주)실리콘브릿지',
  representative: '김태호',
  businessRegistrationNumber: '395-87-00215',
  address:
    '경기 성남시 중원구 갈마치로288번길 14, 731호 (상대원동, 성남 SK V1 tower)',
  phoneNumber: '1670-8891',
  version: '1.0.0',
  privacyPolicy:
    'https://eyevacs.github.io/data/pdf/eyevacs_privacy_policy.pdf',
  termsOfService:
    'https://eyevacs.github.io/data/pdf/eyevacs_terms_of_service.pdf',
  copyright: '&copy; 2020-2024 Silicon Bridge, Inc. All Rights Reserved.',
};

const footerData = env.REACT_APP_FOOTER_DATA
  ? JSON.parse(env.REACT_APP_FOOTER_DATA)
  : defaultFooterData;

const Footer = () => (
  <Container>
    {footerData.companyName && (
      <>
        {footerData.companyName}
        <br />
      </>
    )}
    <FooterWrapper>
      <div>
        {footerData.representative && (
          <>
            대표이사: {footerData.representative}
            <br />
          </>
        )}
        {footerData.businessRegistrationNumber && (
          <>
            사업자등록번호: {footerData.businessRegistrationNumber}
            <br />
          </>
        )}
        {footerData.address && <>주소: {footerData.address}</>}
      </div>
      <div>
        {footerData.version && (
          <>
            버전: {footerData.version}
            <br />
          </>
        )}
        {footerData.phoneNumber && (
          <>
            대표전화: {footerData.phoneNumber}
            <br />
          </>
        )}
        {footerData.businessRegistrationNumber && (
          <>통신판매신고: {footerData.businessRegistrationNumber}</>
        )}
      </div>
    </FooterWrapper>
    <span>
      {footerData.privacyPolicy && (
        <a
          href={footerData.privacyPolicy}
          target="_blank"
          rel="noopener noreferrer"
        >
          개인정보처리방침
        </a>
      )}
      {footerData.privacyPolicy && footerData.termsOfService && ' | '}
      {footerData.termsOfService && (
        <a
          href={footerData.termsOfService}
          target="_blank"
          rel="noopener noreferrer"
        >
          이용약관
        </a>
      )}
    </span>
    {footerData.copyright && (
      <span dangerouslySetInnerHTML={{ __html: footerData.copyright }} />
    )}
  </Container>
);

export default Footer;

const Container = styled.footer`
  margin-top: auto;
  display: flex;
  flex-direction: column;
  color: #959ca3;
  font-size: 12.5px;
  padding: 18px 20px;
  word-break: keep-all;
  background-color: #000000;
`;

const FooterWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  margin: 8px 0;

  & > div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    &:first-child {
      margin-right: 32px;
    }
  }
`;
