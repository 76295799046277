import { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import {
  Card,
  Dropdown,
  Menu,
  message,
  Table,
  TableProps,
  Typography,
} from 'antd';

import { renderDate, renderPrice } from '@utils/index';
import { Facility, Payment, PaymentType, VehicleAccess } from '@utils/models';

import useAxios from '@hooks/useAxios';
import axiosError from '@utils/axios-error';
import useSWR from 'swr';

import { currentSiteId } from '@recoil/current-site';
import { stringifyUrl } from 'query-string';
import { useRecoilValue } from 'recoil';
import { env } from '@utils/env';

const useTargets = () => {
  const siteId = useRecoilValue(currentSiteId);
  const { data } = useSWR<{ facilities: Facility[] }>(
    stringifyUrl({
      url: '/facilities',
      query: { siteId, kind: 'prepay_machine|exit_auto_pay_machine' },
    })
  );

  return useMemo(() => data?.facilities, [data?.facilities]);
};

const usePrintPayment = (va?: VehicleAccess, targets?: Facility[]) => {
  const axios = useAxios();
  const name = useCallback(
    (id: string) => targets?.find((t) => t._id === id)?.name || id,
    [targets]
  );

  return useCallback(
    (id: string, payment: Payment) =>
      axios({
        method: 'POST',
        url: '/test/websocket-custom',
        baseURL: env.REACT_APP_DEVICE_URL,
        data: {
          id,
          data: {
            event_name: 'print_payment',
            external_id: va?._id,
            type: payment.paidType,
          },
        },
      })
        .then(() => message.success(`${name(id)}에서 영수증을 출력합니다.`))
        .catch(axiosError('영수증 출력 요청 전송 중 오류가 발생했습니다')),
    [axios, name, va?._id]
  );
};

interface Props extends TableProps<Payment> {
  va?: VehicleAccess;
  small?: boolean;
  cardless?: boolean;
  target?: Facility['_id'];
}

const PaymentPayments = ({ va, small, cardless, target, ...props }: Props) => {
  const targets = useTargets();
  const printPayment = usePrintPayment(va, targets);
  const t = useMemo(() => target || targets?.[0]?._id, [target, targets]);
  const none = <Typography.Text type="secondary">없음</Typography.Text>;

  const content = (
    <SpecialTable
      {...props}
      loading={!va}
      dataSource={[
        va?.prepayment_id,
        va?.payment_id,
        va?.postpayment_id,
      ].filter((v): v is Payment => v !== undefined)}
      pagination={false}
      size={small ? 'small' : undefined}
      rowKey="paidType"
      columns={[
        {
          title: '정산유형',
          dataIndex: 'paidType',
          align: 'center',
          render: (paidType: Payment['paidType']) => PaymentType[paidType],
        },
        {
          title: '결제금액',
          dataIndex: ['price', 'total'],
          align: 'right',
          render: (v) => renderPrice(v),
        },
        {
          title: '할인금액',
          dataIndex: ['price', 'discount'],
          align: 'right',
          render: (v) => renderPrice(v),
        },
        {
          title: '결제수단',
          dataIndex: 'method',
          align: 'center',
          render: (value: '01' | '02' = '02') =>
            ({ '01': '현금', '02': '신용카드' }[value]),
        },
        {
          title: '카드사',
          dataIndex: ['cardInfo', 'description'],
          align: 'center',
        },
        {
          title: '카드번호',
          dataIndex: ['cardInfo', 'number'],
          align: 'center',
          render: (v) => (v === '0000-000-000-****' ? none : v),
        },
        {
          title: '결제코드',
          dataIndex: ['cardInfo', 'transitionCode'],
          align: 'center',
          render: (v) => (v === '00' ? none : v),
        },
        {
          title: '승인번호',
          dataIndex: ['cardInfo', 'approvedNumber'],
          align: 'center',
          render: (v) => (v === '0000000000' ? none : v),
        },
        {
          title: '승인일시',
          dataIndex: ['cardInfo', 'approvedAt'],
          align: 'center',
          render: (date: string) => renderDate(date),
        },
        {
          title: '영수증',
          align: 'center',
          render: (payment: Payment) => (
            <Dropdown.Button
              type="text"
              size="small"
              onClick={() => t && printPayment(t, payment)}
              overlay={
                <Menu onClick={({ key }) => printPayment(key, payment)}>
                  {targets?.map(({ _id, name }) => (
                    <Menu.Item key={_id} disabled={_id === t}>
                      {name}에서 출력하기
                    </Menu.Item>
                  ))}
                </Menu>
              }
            >
              출력하기
            </Dropdown.Button>
          ),
        },
      ]}
    />
  );

  return cardless ? (
    content
  ) : (
    <Card>
      <Title>결제 내역</Title>
      {content}
    </Card>
  );
};

export default PaymentPayments;

const Title = styled.div`
  margin-bottom: 8px;
  font-weight: bold;
  font-size: 16.9px;
`;

const SpecialTable = styled(Table)`
  border: 1px solid rgb(240, 240, 240);
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;

  [data-theme='dark'] & {
    border-color: #303030;
  }

  &&& {
    .ant-table-cell-row-hover {
      background-color: #fafafa;

      [data-theme='dark'] & {
        background-color: #262626;
      }
    }
  }
` as typeof Table;
