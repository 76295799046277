import axios from 'axios';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';

import { accessTokenState, refreshTokenState, useLogout } from '@recoil/auth';
import { env } from '@utils/env';
const customAxios = axios.create({
  baseURL: env.REACT_APP_API_URL,
});

const useAxios = () => {
  const [accessToken, setAccessToken] = useRecoilState(accessTokenState);
  const [refreshToken] = useRecoilState(refreshTokenState);
  const logout = useLogout();

  useEffect(() => {
    const interceptor = customAxios.interceptors.request.use((config) => {
      return {
        ...config,
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + accessToken,
          ...config.headers,
        },
      };
    });
    return () => {
      customAxios.interceptors.request.eject(interceptor);
    };
  }, [accessToken]);

  useEffect(() => {
    const interceptor = customAxios.interceptors.response.use(
      (res) => res,
      async (err) => {
        if (err?.response?.status !== 401) return Promise.reject(err);

        try {
          if (typeof refreshToken !== 'string' || !refreshToken) {
            console.log('no token');
            logout();
            return Promise.reject();
          }
          const res = await axios.post(
            env.REACT_APP_API_URL + '/auth/refresh',
            { refresh_token: refreshToken }
          );
          setAccessToken(res.data.access_token);
          err.config.headers[
            'Authorization'
          ] = `Bearer ${res.data.access_token}`;
          return await axios(err.config);
        } catch (err: any) {
          console.log('not called?', err.response);
          if (err.response?.status === 401) {
            console.log('not called again?', err.response);
            logout();
          }
          return Promise.reject(err);
        }
      }
    );
    return () => {
      customAxios.interceptors.response.eject(interceptor);
    };
  }, [refreshToken, logout, setAccessToken]);

  return customAxios;
};

export default useAxios;
