import { FC, useEffect, useState, useMemo } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { userInfoState } from '@recoil/auth';
import styled from 'styled-components';

import {
  Layout,
  Popover,
  Divider,
  Tooltip,
  Result,
  Button,
  Typography,
} from 'antd';
import { MenuOutlined } from '@ant-design/icons';

import { PRIVATE_ROUTE, PUBLIC_ROUTE } from '@routes/routes.constants';
import useWindowSize from '@hooks/useWindowSize';
import { useLogout } from '@recoil/auth';
import Sidebar from './Sidebar';
import Footer from './Footer';
import ProfileImage from './ProfileImage';

import themeState, { THEMES } from '@recoil/theme';
import sidebarState, { SIDEBARS } from '@recoil/sidebar';
import headerState, { HEADERS } from '@recoil/header';
import footerState, { FOOTERS } from '@recoil/footer';

const { DARK } = THEMES;
const { OPEN, COLLAPSED } = SIDEBARS;
const { Header, Content } = Layout;

const Boundary: FC = ({ children }) => (
  <ErrorBoundary
    fallbackRender={({ error }) => (
      <Result
        status={error.name === 'ChunkLoadError' ? 'warning' : 'error'}
        title={
          error.name === 'ChunkLoadError'
            ? '이전 버전의 페이지를 사용하고 있습니다.'
            : '오류가 발생했습니다.'
        }
        subTitle={
          <>
            <span>페이지를 새로고침 후 다시 시도하세요.</span>
            <br />
            <span>
              <Typography.Text keyboard>Ctrl</Typography.Text>+
              <Typography.Text keyboard>Shift</Typography.Text>+
              <Typography.Text keyboard>R</Typography.Text>로 캐시를
              초기화하세요.
            </span>
          </>
        }
        extra={
          <Button type="primary" onClick={() => window.location.reload()}>
            새로고침
          </Button>
        }
      />
    )}
  >
    {children}
  </ErrorBoundary>
);

const ServiceWrapper: FC = ({ children }) => {
  const logout = useLogout();
  const history = useHistory();

  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);

  const [me] = useRecoilState(userInfoState);
  const header = useRecoilValue(headerState);
  const footer = useRecoilValue(footerState);

  const { pathname } = useLocation();
  const isPublic = useMemo(
    () =>
      Object.values(PUBLIC_ROUTE)
        .filter((route) => route !== '/')
        .some((route) => pathname.startsWith(route)),
    [pathname]
  );

  const { width = 1920 } = useWindowSize();
  const isMobileScreen = useMemo(() => width <= 500, [width]);

  const [sidebar, setSidebar] = useRecoilState(sidebarState);
  useEffect(
    () => (isMobileScreen ? setSidebar(COLLAPSED) : void 0),
    [isMobileScreen, setSidebar]
  );

  const theme = useRecoilValue(themeState);
  const { themes, switcher } = useThemeSwitcher();
  useEffect(
    () => switcher({ theme: theme === DARK ? themes.dark : themes.light }),
    [switcher, theme, themes]
  );

  // 퍼블릭 라우트는 레이아웃으로 감싸지 않음
  return isPublic ? (
    <Boundary>{children}</Boundary>
  ) : (
    <Layout
      style={{
        overflowX: isMobileScreen ? 'hidden' : 'unset',
      }}
    >
      <Sidebar />
      <Layout
        className="site-layout"
        style={{
          transition: 'all 0.2s',
          minHeight: '100vh',
          transform: `translateX(${sidebar === OPEN ? 260 : 0}px)`,
          maxWidth: sidebar === OPEN ? width - 260 : width,
          minWidth: isMobileScreen ? '100vw' : 'unset',
        }}
      >
        {header === HEADERS.VISIBLE && (
          <Header
            className="site-layout-background"
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '0px 15px',
              backgroundColor: 'white',
              position: 'relative',
            }}
          >
            <MenuOutlined
              style={{
                fontSize: 20,
                color: 'black',
                zIndex: 999,
                position: 'absolute',
                left: 24,
              }}
              onClick={() =>
                setSidebar((s) => (s === COLLAPSED ? OPEN : COLLAPSED))
              }
            />
            {width <= 460 && sidebar === COLLAPSED ? (
              <LogoWrapper onClick={() => history.push(PRIVATE_ROUTE.MAIN)}>
                <img src="/logo.svg" alt="logo" style={{ width: '200px' }} />
              </LogoWrapper>
            ) : (
              <Tooltip title={me?.permissionGroup?.name} placement="left">
                <ProfileName>{me?.name}</ProfileName>
              </Tooltip>
            )}
            <Popover
              trigger="click"
              placement="bottomRight"
              arrowPointAtCenter
              content={
                <PopoverContents>
                  <Link to="/profile">프로필</Link>
                  <PopoverDivider />
                  <span onClick={logout}>로그아웃</span>
                </PopoverContents>
              }
              open={isPopoverOpen}
              onOpenChange={setIsPopoverOpen}
            >
              <ProfileImage
                size={24}
                style={{
                  cursor: 'pointer',
                  position: 'absolute',
                  right: 24,
                }}
                email={me?.email}
              />
            </Popover>
          </Header>
        )}
        <Content
          style={{
            margin: '24px 16px 0',
            overflow: 'initial',
            overflowX: isMobileScreen && sidebar !== OPEN ? 'unset' : 'hidden',
          }}
        >
          <Boundary>{children}</Boundary>
        </Content>
        {footer === FOOTERS.VISIBLE && <Footer />}
      </Layout>
    </Layout>
  );
};

export default ServiceWrapper;

const ProfileName = styled.span`
  right: 60px;
  font-weight: bold;
  position: absolute;
`;

const PopoverContents = styled.div`
  width: 150px;

  & > span {
    cursor: pointer;
    display: flex;
    width: 100%;
  }
`;

const PopoverDivider = styled(Divider)`
  && {
    margin: 12px 0;
  }

  & > * {
    color: black;
  }
`;

const LogoWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  & > svg {
    height: 25px;
  }
`;
