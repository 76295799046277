import { useState } from 'react';
import { Card, CardProps, Image, Switch } from 'antd';
import styled from 'styled-components';
import useAxios from '@hooks/useAxios';
import RestartButton from './RestartButton';
import RTSPPlayer from '@components/RTSPPlayer';
import { Cam, Facility } from '@utils/models';
import FacilityTag from './FacilityTag';
import { env } from '@utils/env';

// const LPRModes = {
//   정상: '01',
//   열림고정: '02',
// };

interface CamCardProps extends CardProps {
  cam?: Cam;
  resolution?: string;
  facility: Facility;
}

const CamCard = ({ facility, cam, resolution, ...props }: CamCardProps) => {
  const axios = useAxios();
  const [connect, setConnect] = useState(true);

  // 2022-05-06 capture_start 제거 요청 (불필요, 이영길 이사)
  // useEffect(() => {
  //   if (!facility) return;
  //   axios.post(`${env.REACT_APP_DEVICE_URL}/test/websocket-custom`, {
  //     id: facility._id,
  //     data: { event_mode: 'capture_start' },
  //   });
  // }, [axios, facility]);

  return (
    <StyledCard
      {...props}
      title={
        <CardTitle>
          <span>{cam?.name || facility.name}</span>
          <FacilityTag facility={facility} />
        </CardTitle>
      }
      extra={
        <RestartButton
          facilityId={facility._id}
          size={props.size === 'small' ? 'small' : undefined}
        />
      }
      cover={
        !cam ? (
          <Image
            alt={`${facility.name} 화면`}
            src={
              connect
                ? facility.currentImage &&
                  `${env.REACT_APP_API_URL}/facilities/${facility._id}/${facility.currentImage}`
                : ''
            }
            fallback={`https://via.placeholder.com/${
              resolution || '960x540'
            }.png/222222?text=...`}
          />
        ) : (
          <RTSPPlayer url={cam.rtsp} />
        )
      }
    >
      {!cam && facility && (
        <>
          <SwitchWrapper style={{ display: 'flex', width: '100%' }}>
            <SwitchLabel>연결해제</SwitchLabel>
            <Switch
              checked={connect}
              onChange={(v) => {
                setConnect(v);
                axios.post(
                  `${env.REACT_APP_DEVICE_URL}/test/websocket-custom`,
                  {
                    id: facility._id,
                    data: { event_mode: v ? 'capture_start' : 'capture_end' },
                  }
                );
              }}
            />
            <SwitchLabel>연결</SwitchLabel>
          </SwitchWrapper>
        </>
      )}
    </StyledCard>
  );
};

export default CamCard;

export const PayMachineCard = ({
  facility,
  ...props
}: { facility: Facility } & CardProps) => {
  return <CamCard {...props} facility={facility} resolution="512x384" />;
};

const StyledCard = styled(Card)`
  .ant-card-head {
    padding: 0 16px;
  }

  .ant-card-cover {
    position: relative;
  }

  .ant-card-body {
    padding: 16px;
  }
`;

const CardTitle = styled.span`
  display: flex;
  align-items: center;

  & > span:first-child {
    margin-right: 8px;
  }
`;

const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SwitchLabel = styled.label`
  font-weight: bold;
  margin-left: 8px;

  &:first-of-type {
    margin-left: 0;
    margin-right: 8px;
  }
`;
