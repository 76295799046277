export const env = {
  REACT_APP_API_URL:
    window.env?.REACT_APP_API_URL || process.env.REACT_APP_API_URL,
  REACT_APP_DEVICE_URL:
    window.env?.REACT_APP_DEVICE_URL || process.env.REACT_APP_DEVICE_URL,
  REACT_APP_WS_URL:
    window.env?.REACT_APP_WS_URL || process.env.REACT_APP_WS_URL,
  REACT_APP_KAKAO_KEY:
    window.env?.REACT_APP_KAKAO_KEY || process.env.REACT_APP_KAKAO_KEY,
  REACT_APP_TOSS_CLIENT_KEY:
    window.env?.REACT_APP_TOSS_CLIENT_KEY ||
    process.env.REACT_APP_TOSS_CLIENT_KEY,
  REACT_APP_FOOTER_DATA:
    window.env?.REACT_APP_FOOTER_DATA || process.env.REACT_APP_FOOTER_DATA,
};
