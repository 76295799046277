import { env } from '@utils/env';
import { AxiosInstance } from 'axios';

export async function setLocked(
  axios: AxiosInstance,
  facility: any,
  locked: boolean // 열림고정 or 정상
) {
  const mode = locked ? 'up_lock' : 'normal';
  await axios.patch(`/facilities/${facility._id}`, {
    configuration: {
      ...facility.configuration,
      mode,
    },
  });
  await axios.post(`${env.REACT_APP_DEVICE_URL}/test/mode-change`, {
    id: facility._id,
    data: { mode },
  });
}

export async function setPassing(
  axios: AxiosInstance,
  facility: any,
  open: boolean // 프리패스 or 경비확인
) {
  await axios.patch(`/facilities/${facility._id}`, {
    configuration: {
      ...facility.configuration,
      recog_gate_open: open,
    },
  });
  await axios.post(`${env.REACT_APP_DEVICE_URL}/test/mode-change`, {
    id: facility._id,
    data: { mode: facility.configuration?.mode },
  });
}
