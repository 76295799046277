import { FC, useCallback, useState } from 'react';
import {
  Button,
  ButtonProps,
  Col,
  Form,
  InputNumber,
  message,
  Row,
  RowProps,
} from 'antd';
import currentSiteState from '@recoil/current-site';
import CouponSelect, { CouponSelectProps, useCoupons } from './CouponSelect';

import useAxios from '@hooks/useAxios';
import queryString from 'query-string';
import { VehicleAccess } from '@utils/models';
import { useRecoilValue } from 'recoil';
import { env } from '@utils/env';

interface Props extends RowProps {
  va?: VehicleAccess;
  revalidate?: () => void;
  facilityId?: string; // 할인권 적용 후 변경된 요금이 전송될 정산기 ID

  selectProps?: Partial<CouponSelectProps>;
  buttonProps?: ButtonProps;
}

export const useRegister = ({
  va,
  facilityId,
  revalidate: r,
  selectProps,
  buttonProps,
}: Props) => {
  const axios = useAxios();
  const { coupons, createCoupon, isValidating, revalidate: rr } = useCoupons();

  const [key, setKey] = useState<string>();
  const [loading, setLoading] = useState(false);
  const revalidate = useCallback(() => Promise.all([r?.(), rr()]), [r, rr]);
  const [couponCount, setCouponCount] = useState(1);

  const applyCoupon = useCallback(async () => {
    if (!va) return message.error('차량을 선택해 주세요.');
    if (!va.vehicle)
      return message.error('미인식 차량에는 할인권을 등록할 수 없습니다.');
    if (!key) return message.error('할인권을 선택해 주세요.');

    setLoading(true);
    const res = await axios.get<{ vehicleAccess: VehicleAccess }>(
      queryString.stringifyUrl({
        url: '/vehicle-accesses/' + va._id,
      })
    );

    const newVa = res.data.vehicleAccess;

    const discountCouponList = newVa.payment.discountCouponList.map(
      (v) => v._id
    );

    discountCouponList.push(await createCoupon(key));

    const payment = { discountCouponList };

    await axios.patch(`/vehicle-accesses/${va._id}`, { payment });
    if (facilityId) {
      await axios.post(`${env.REACT_APP_DEVICE_URL}/test/websocket-custom`, {
        id: facilityId,
        data: { event_name: 'trans_vehicle', external_id: va._id },
      });
    }

    await revalidate();

    // setKey(undefined);
    // setCouponCount(1);
  }, [axios, createCoupon, facilityId, key, revalidate, va]);

  const applyCoupons = useCallback(async () => {
    try {
      if (!va) return message.error('차량을 선택해 주세요.');
      if (!va.vehicle)
        return message.error('미인식 차량에는 할인권을 등록할 수 없습니다.');
      if (!key) return message.error('할인권을 선택해 주세요.');

      for (let i = 0; i < couponCount; i++) {
        await applyCoupon();
      }
      message.success(`${va.vehicle} 차량에 할인권이 등록되었습니다.`);
    } catch (error: any) {
      message.error(
        error.response
          ? error.response.data.message
          : '할인권 등록 중 에러가 발생했습니다.'
      );
    } finally {
      setLoading(false);
    }
  }, [applyCoupon, couponCount, key, va]);

  const select = (
    <CouponSelect
      coupons={coupons}
      value={key}
      onChange={(v) => setKey(v)}
      {...selectProps}
    />
  );

  const count = (
    <Form.Item
      name="couponCount"
      initialValue={couponCount}
      rules={[{ required: true, message: '개수를 입력해 주세요.' }]}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <InputNumber
          min={1}
          value={couponCount}
          onChange={(value) => value && setCouponCount(value)}
          style={{ textAlign: 'center' }}
        />
        <span style={{ marginLeft: 8 }}>개</span>
      </div>
    </Form.Item>
  );

  const button = (
    <Button
      type="primary"
      onClick={() => {
        applyCoupons();
      }}
      loading={loading}
      disabled={isValidating}
      style={{ marginLeft: 8 }}
      {...buttonProps}
    >
      {buttonProps?.children || '적용'}
    </Button>
  );

  return { select, count, button };
};

const CouponRegister: FC<Props> = ({
  va,
  revalidate,
  facilityId,
  selectProps,
  buttonProps,
  ...props
}) => {
  const { select, button, count } = useRegister({
    va,
    revalidate,
    facilityId,
    selectProps,
    buttonProps,
  });

  return (
    <Col>
      <Row {...props}>
        <Col flex={1}>{select}</Col>
        <Col>{button}</Col>
      </Row>
      {count}
    </Col>
  );
};

export default CouponRegister;
