import { useCallback, useState } from 'react';
import useAxios from '@hooks/useAxios';
import { Button, ButtonProps, message, Popconfirm } from 'antd';
import { env } from '@utils/env';

interface RestartButtonProps extends ButtonProps {
  facilityId?: string;
}

export default ({ facilityId, ...props }: RestartButtonProps) => {
  const axios = useAxios();
  const [loading, setLoading] = useState(false);

  const onConfirm = useCallback(async () => {
    if (!facilityId) return;
    setLoading(true);
    try {
      await axios.post(`/facilities/${facilityId}/refresh`);
      await axios.post(`${env.REACT_APP_DEVICE_URL}/test/reset`, {
        id: facilityId,
      });
      message.success('장비 재시작이 완료되었습니다.');
    } catch (error: any) {
      message.error('장비 재시작 중 문제가 발생했습니다.');
    } finally {
      setLoading(false);
    }
  }, [axios, facilityId]);

  return (
    <Popconfirm title="정말 재시작하시겠습니까?" onConfirm={onConfirm}>
      <Button loading={loading} disabled={!facilityId} {...props}>
        재시작
      </Button>
    </Popconfirm>
  );
};
