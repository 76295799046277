import { Client } from '@hapi/nes/lib/client';
import { FC, createContext, useRef } from 'react';

import useConnect from './hooks/connect';
import useVoc from './hooks/subscribe/voc';
import useCreditCall from './hooks/subscribe/credit_call';
import useReservation from './hooks/subscribe/reservation';
import useFacilityIssue from './hooks/subscribe/facility_issue';
import { env } from '@utils/env';

export const NesContext = createContext<Client | null>(null);
export const NesProvider: FC<{}> = (props) => {
  const url = env.REACT_APP_WS_URL;
  const clientRef = useRef<Client | null>(url ? new Client(url) : null);

  useConnect({ clientRef });
  useVoc({ clientRef });
  useCreditCall({ clientRef });
  useReservation({ clientRef });
  useFacilityIssue({ clientRef });

  return <NesContext.Provider value={clientRef.current} {...props} />;
};
