import { format, formatDuration, intervalToDuration } from 'date-fns';
import { ko } from 'date-fns/locale';
import { env } from './env';

export const IMAGE_CDN = `${env.REACT_APP_DEVICE_URL}/images`;
export const IMAGE_FALLBACK =
  'https://via.placeholder.com/192x108.png/eeeeee?text=테스트';

export const sortString = (key: string) => (a: any, b: any) =>
  (a[key] || '').toString().localeCompare(b[key] || '');

export const sortData = (obj: any, sorter: any) => {
  return obj.slice().sort((a: any[], b: any[]) => {
    if (sorter) {
      const i = a[sorter.columnKey];
      const j = b[sorter.columnKey];
      const criterion = sorter.order === 'ascend' ? -1 : 1;
      if (i < j) return criterion;
      if (i > j) return -criterion;
    }

    return 0;
  });
};

export const renderPrice = (
  price: number | undefined,
  placeholder: string | undefined = '-',
  freePlaceholder: string | undefined = undefined
) =>
  typeof price === 'number' && !isNaN(price)
    ? price <= 0 && freePlaceholder !== undefined
      ? freePlaceholder
      : price.toLocaleString() + '원'
    : placeholder;

export type DateValue = Date | string | number;
export type OptionalDateValue = DateValue | null | undefined;

export const renderDate = (date: OptionalDateValue, verbose = true) =>
  date ? format(new Date(date), `yyyy-MM-dd${verbose ? ' HH:mm:ss' : ''}`) : '';

export const formatDistanceTo = (date: DateValue, from?: DateValue) => {
  if (!date) return '-';
  return formatDuration(
    intervalToDuration({
      start: new Date(date),
      end: from ? new Date(from) : new Date(),
    }),
    { locale: ko }
  );
};

export const getKeyByValue = (
  object: any,
  value: string
): string | undefined => {
  return Object.keys(object).find((key) => object[key] === value);
};

export const getBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
};
